<template>
  <gf-base>
    <gf-card title="New Price List" subtitle="Create New Price List">
      <template #toolbar>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <gf-container v-loading="loading">
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "/>
                      <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    </g>
                  </svg>
                </span>
              </template>
              Input Price List Details on the Form Below
            </gf-alert>
          </template>
          <el-form label-width="140px" :model="priceList" :rules="priceListRules" ref="priceList">
            <el-form-item label="Price List Name" prop="name">
              <el-input v-model="priceList.name"></el-input>
            </el-form-item>
            <el-form-item label="Price List Type" prop="price_list_type">
              <el-select placeholder="Sell" style="width: 100%" v-model="priceList.price_list_type">
                <el-option v-for="(pl,index) in priceListType" :key="index" :value="index" :label="pl">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Code" prop="code">
              <el-input v-model="priceList.code">
              </el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="primary" @click="submit">
              <template #icon>
                <span class="svg-icon svg-icon-white">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                      <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                    </g>
                  </svg>
                </span>
              </template>
              Save
            </gf-button>
            <gf-button type="secondary" @click="clear">
              <template #icon>
                <span class="svg-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                  </svg>
                </span>
              </template>
              Clear
            </gf-button>
          </template>
        </gf-form>
        <template #aside>
          <gf-helper title="Manage Prices" content="You can manage your product prices by creating price list"></gf-helper>
        </template>
      </gf-container>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import PriceListService from '@/services/v1/PriceList'

export default {
  data () {
    return {
      // Main variable
      priceList: {
        name: null,
        price_list_type: 'Buy',
        code: null
      },
      priceListType: [
        'Buy',
        'Sell'
      ],

      // ui
      loading: false,

      // Rules
      priceListRules: {
        name: [
          { required: true, message: 'Price List Name is require', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    clear () {
      this.$ClearField('priceList')
    },
    back () {
      this.$router.back()
    },
    submit () {
      this.$refs.priceList.validate()
        .then(async () => {
          try {
            this.loading = true

            const priceList = this.$_.cloneDeep(this.priceList)
            this.$Sanitize(priceList)

            const plService = new PriceListService()
            await plService.create(priceList)

            this.$message.success('Price List was successfully created')
            this.$router.push({ name: 'pricelist-list' })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory', route: '' },
      { title: 'Price Lists' },
      { title: 'Create' }
    ])
  }
}
</script>
